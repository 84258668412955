import React, { useState, useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { p, Box } from "@material-ui/core";
import { FiMenu } from "react-icons/fi";
import logo from "../assets/logo.png";
import CoinGecko from "coingecko-api";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/NavBar.css";

const CoinGeckoClient = new CoinGecko();

const NavBar = () => {
  const [expanded, setExpanded] = useState(false);
  const [btcPrice, setBtcPrice] = useState(0);
  const [dayChangePct, setDayChangePct] = useState(0);
  const [marketCap, setMarketCap] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getBtcCurrentPrice();
  }, []);

  useEffect(() => {
    if (btcPrice && dayChangePct && marketCap) setLoading(false);
  }, [btcPrice, dayChangePct, marketCap]);

  // function pulls BTC's current price from Nomics API
  const getBtcCurrentPrice = async () => {
    try {
      const { data } = await CoinGeckoClient.coins.fetch("bitcoin");

      setBtcPrice(data.market_data.current_price.usd);
      setDayChangePct(data.market_data.price_change_percentage_24h);
      setMarketCap(data.market_data.market_cap.usd);
    } catch (error) {
      console.errer(error.message);
    }
  };

  // abreviates long number (ie for market cap)
  function abbreviateNumber(n) {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  }

  return (
    <Navbar
      className="NavBar"
      expanded={expanded}
      onToggle={() => setExpanded(!expanded)}
      expand="lg"
    >
      <Nav.Link href="/">
        <Navbar.Brand>
          <img className="logo" src={logo} />
        </Navbar.Brand>
      </Nav.Link>

      {!loading && (
        <div>
          <p className="tickerContent">
            <span className="bolded">BTC Price: </span>
            {`$${parseFloat(btcPrice)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
          </p>

          <p className="tickerContent">
            <span className="bolded">24hr Change: </span>
            <span
              style={{
                color: dayChangePct > 0 ? "green" : "red",
              }}
            >
              {`${dayChangePct.toFixed(2)}%`}
            </span>
          </p>

          <p className="tickerContent">
            <span className="bolded">Market Cap: </span>
            {abbreviateNumber(marketCap)}
          </p>
        </div>
      )}

      <Navbar.Toggle
        style={{ border: "none", outline: "none" }}
        aria-controls="basic-navbar-nav"
      >
        <FiMenu color="lightgrey" size={30} />
      </Navbar.Toggle>

      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto" onSelect={() => setExpanded(false)}>
          <Nav.Item>
            <Nav.Link href="/">
              <p className="navLink">Home</p>
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link href="/opportunity-cost">
              <p className="navLink">Opportunity Cost</p>
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link href="/research">
              <p className="navLink">Research</p>
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link href="/contact">
              <p className="navLink">Contact</p>
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
