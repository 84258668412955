import React from "react";
import "../styles/Footer.css";

const Footer = () => {
  return (
    <div className="Footer">
      <p>
        Any information or written material provided to you by 21st Paradigm
        Consulting is monetary consulting, and not investment advice. Bitcoin is
        a decentralized monetary network that individuals can voluntarily adopt
        & utilize. 21st Paradigm Consulting does not provide insight or advice
        on investment securities.
      </p>
    </div>
  );
};

export default Footer;
