import React, { useState, useRef } from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import { Form, Button } from "react-bootstrap";
import { MdEmail } from "react-icons/md";
import { FaTwitter } from "react-icons/fa";
import Spinner from "react-bootstrap/Spinner";
import emailjs from "emailjs-com";
import dylanPic from "../assets/dylan.png";
import tylerPic from "../assets/tyler.jpeg";
import firebase from "firebase";
import "../styles/Contact.css";

const Contact = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const foundersRef = useRef(null);

  // function sends email
  const sendEmail = (e) => {
    // show loader
    setSpinner(true);

    // prevent page from refreshing
    e.preventDefault();

    // send email
    emailjs
      .sendForm(
        "service_gpci81z",
        "template_3dshrqi",
        e.target,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .catch(() => alert("Error sending message"))
      .then(() => {
        setEmailSent(true);
        setSpinner(false);
      });
  };

  // function handles scroll when founders names are clicked
  const handleBioScroll = () => {
    if (foundersRef.current) {
      window.scrollTo({
        behavior: "smooth",
        top: foundersRef.current.offsetTop,
      });
    }
  };

  return (
    <div className="Contact">
      <h1 className="header">Contact Us</h1>

      <div className="contactContainer">
        {!emailSent ? (
          <Form className="form" onSubmit={sendEmail}>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>Name</Form.Label>
              <Form.Control
                className="input"
                placeholder="Enter your name..."
                name="name"
                required
              />
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                className="input"
                type="email"
                placeholder="Enter your email..."
                name="email"
                required
              />
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>Business (optional)</Form.Label>
              <Form.Control
                className="input"
                placeholder="Enter your business..."
                name="business"
              />
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>Message</Form.Label>
              <Form.Control
                className="input"
                as="textarea"
                placeholder="Enter your message..."
                rows={8}
                name="message"
                required
              />
            </Form.Group>

            <Button
              className="button"
              onClick={() => firebase.analytics().logEvent("email-sent")}
              variant="primary"
              type="submit"
              block
            >
              {!spinner ? (
                "Submit"
              ) : (
                <Spinner animation="border" size="sm" variant="dark" />
              )}
            </Button>
          </Form>
        ) : (
          <h3 className="emailSent">Message sent! Expect a reply very soon.</h3>
        )}

        <div className="contactInfo">
          <h5>Founders</h5>
          <p onClick={handleBioScroll}>Dylan LeClair</p>
          <p onClick={handleBioScroll}>Tyler Laroche</p>

          <hr className="hr" />

          <h5>
            Emails <MdEmail />
          </h5>

          <p>
            <a href="mailto:Dylan@21stParadigm.com" target="_blank">
              Dylan@21stParadigm.com
            </a>
          </p>

          <p>
            <a href="mailto:Dylan@21stParadigm.com" target="_blank">
              Tyler@21stParadigm.com
            </a>
          </p>

          <hr className="hr" />

          <h5>
            Twitter <FaTwitter />
          </h5>
          <a href="https://twitter.com/DylanLeClair_" target="_blank">
            @DylanLeClair_
          </a>
        </div>
      </div>

      <div className="bioContainer" ref={foundersRef}>
        <Typography variant="h4">Founders</Typography>

        <Grid alignItems="stretch" container>
          <Grid style={{ marginTop: 30 }} md={5} item>
            <img className="bioImage" src={dylanPic} width="100%" />
            <Typography>
              <Box marginTop={2} fontSize={20}>
                Dylan is a 20 year old with a passion for Bitcoin, and the
                economic liberation that comes along with it. Aside from his
                work at 21st Paradigm, he is on media/content team at Bitcoin
                Magazine. Read his pieces{" "}
                <a
                  href="https://bitcoinmagazine.com/authors/dylan-leclair"
                  target="_blank"
                >
                  here.
                </a>
              </Box>
            </Typography>
          </Grid>

          <Grid md={2} item />

          <Grid style={{ marginTop: 30 }} md={5} item>
            <img className="bioImage" src={tylerPic} width={"100%"} />
            <Typography>
              <Box marginTop={2} fontSize={20}>
                Tyler Laroche is a 19 year old with a profound interest in
                Bitcoin and Austrian economics. Along with consulting at 21st
                Paradigm, Tyler is currently a full-time student majoring in
                Business Administration and Economics at the University of
                Vermont.
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Contact;
