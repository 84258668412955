import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import Home from "./pages/Home";
import OpportunityCost from "./pages/OpportunityCost";
import Research from "./pages/Research";
import AddArticle from "./pages/AddArticle";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";
import "./styles/App.css";

const App = () => {
  return (
    <div className="App">
      <div className="router">
        <Router>
          <NavBar />

          <Switch>
            <Route exact path="/" component={Home} />

            <Route path="/opportunity-cost" component={OpportunityCost} />

            <Route path="/research" component={Research} />

            <Route path="/add" component={AddArticle} />

            <Route path="/contact" component={Contact} />
          </Switch>
        </Router>
      </div>

      <Footer />
    </div>
  );
};

export default App;
