import React from "react";
import { makeStyles, Grid, Typography, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "auto",
    marginBottom: theme.spacing(5),
    border: "1px solid #171717",
    borderRadius: 20,
    cursor: "pointer",
  },

  image: {
    width: "100%",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },

  contentContainer: {
    padding: theme.spacing(3),
  },
}));
const ArticleComponent = ({ article }) => {
  const classes = useStyles();

  return (
    <Grid
      className={classes.container}
      onClick={() => window.open(article.url)}
      md={6}
      item
    >
      <img className={classes.image} src={article.image} />

      <div className={classes.contentContainer}>
        <Typography variant="h4">{article.title}</Typography>

        <Typography variant="h6">
          <Box marginTop={1} color="gray">
            {article.description}
          </Box>
        </Typography>
      </div>
    </Grid>
  );
};

export default ArticleComponent;
