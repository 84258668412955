import React, { useRef } from "react";
import Services from "../components/Services";
import { Card } from "@material-ui/core";
import { Carousel } from "react-responsive-carousel";
import styles from "react-responsive-carousel/lib/styles/carousel.min.css";
import logoGif from "../assets/logoGif.gif";
import "../styles/Home.css";

const Home = () => {
  const missionRef = useRef(null);
  const servicesRef = useRef(null);

  const handleMissionClick = () => {
    if (missionRef.current) {
      window.scrollTo({
        behavior: "smooth",
        top: missionRef.current.offsetTop,
      });
    }
  };

  const handleStatusClick = () => {
    if (servicesRef.current) {
      window.scrollTo({
        behavior: "smooth",
        top: servicesRef.current.offsetTop,
      });
    }
  };

  return (
    <div className="Home">
      <div className="homeImageContainer">
        <div className="sloganContainer">
          <Carousel
            showThumbs={false}
            showArrows={false}
            showIndicators={false}
            showStatus={false}
            autoPlay={true}
            infiniteLoop={true}
            interval={5000}
            transitionTime={1000}
          >
            <div>
              <h1 className="slogan">Fix the Money, Fix the World.</h1>

              <p className="homeButton" onClick={handleMissionClick}>
                Read our mission ↓
              </p>
            </div>

            <div>
              <h1 className="slogan">
                It's not too late to be among the first.
              </h1>

              <p className="homeButton" onClick={handleMissionClick}>
                Read our mission ↓
              </p>
            </div>

            <div>
              <h1 className="slogan">21,000,000 Bitcoin.</h1>
              <h1 className="slogan">21st Century Money.</h1>
              <h1 className="slogan">21st Paradigm.</h1>

              <p className="homeButton" onClick={handleMissionClick}>
                Read our mission ↓
              </p>
            </div>
          </Carousel>
        </div>
      </div>

      <div className="rowContainer" ref={missionRef}>
        <img src={logoGif} width="50%" style={{ marginBottom: "50px" }} />

        <Card
          className="missionStatementContainer"
          style={{ backgroundColor: "#171717", color: "lightgrey" }}
        >
          <h1 className="headerCentered">Our Mission</h1>

          <p>
            At 21st Paradigm, we are committed to helping as many individuals
            and businesses as possible understand Bitcoin & develop client
            specific accumulation & holding strategies as Bitcoin continues its
            ascent towards global reserve currency status.
          </p>

          <p>
            We hold a fundamental belief that the primary driver of wealth in
            the 21st Century will not be what one does for a living, but rather
            how early one adopts Bitcoin as a primary store of value and unit of
            account for economic calculation.
          </p>

          <p>The advent of absolute scarcity changes everything.</p>

          <p>21,000,000 Bitcoin. 21st Century. 21st Paradigm. </p>

          <a className="homeButton" onClick={handleStatusClick}>
            Our services ↓
          </a>
        </Card>
      </div>

      <div
        className="rowContainer"
        ref={servicesRef}
        style={{ backgroundColor: "#171717" }}
      >
        <Services />
      </div>

      <div className="rowContainer">
        <div className="contentContainer">
          <iframe
            className="gif"
            src="https://giphy.com/embed/Uq3utscFzBrhS2NU9V"
            frameBorder="0"
            title="Bitcoin GIF"
            allowFullScreen
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
