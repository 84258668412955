import React, { useState, useEffect } from "react";
import {
  makeStyles,
  LinearProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import ArticleComponent from "../components/ArticleComponent";
import firebase from "firebase";
import "../styles/Research.css";

const useStyles = makeStyles({
  colorPrimary: {
    backgroundColor: "grey",
  },
  bar: {
    backgroundColor: "#ff9900",
  },
});

const Research = () => {
  const classes = useStyles();

  const [articles, setArticles] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getArticles();
  }, []);

  // function gets articles from database
  const getArticles = async () => {
    try {
      const articlesRef = await firebase
        .firestore()
        .collection("articles")
        .get();

      if (!articlesRef.empty) {
        let articlesArr = [];
        articlesRef.forEach((article) => {
          articlesArr.push(article.data());
        });
        setArticles(articlesArr);
      } else {
        setArticles(null);
      }
    } catch (error) {
      alert("Error retreiving articles");
    } finally {
      setLoading(false);
    }
  };

  if (!loading) {
    return (
      <div style={{ padding: 20 }}>
        {articles ? (
          articles
            .sort((a, b) => b.added - a.added)
            .map((article, index) => {
              return (
                <Grid container>
                  <ArticleComponent article={article} key={index} />
                </Grid>
              );
            })
        ) : (
          <Typography className="articleContainer" variant="h4" align="center">
            No articles 😥
          </Typography>
        )}
      </div>
    );
  } else
    return (
      <LinearProgress
        classes={{
          colorPrimary: classes.colorPrimary,
          bar: classes.bar,
        }}
      />
    );
};

export default Research;
