import React from "react";
import Calculator from "../components/Calculator";
import BtcHistoricalChart from "../components/BtcHistoricalChart";
import { Card } from "@material-ui/core";
import "../styles/OpportunityCost.css";

const OpportunityCost = () => {
  return (
    <div className="OpportunityCost">
      <h1 className="header">Opportunity Cost</h1>

      <Card
        className="opportunityCostStatementContainer"
        style={{ backgroundColor: "#171717", color: "lightgrey" }}
      >
        We have included this basic dollar cost averaging function on our site
        to demonstrate the massive opportunity cost that arises over time when
        valuing things in Bitcoin. Although volatile day to day, there has not
        been a better strategy over the past decade than using Bitcoin as your
        unit of account for economic calculation. Said more simply, instead of
        thinking of past, present, and future expenditure and investment in
        dollar terms, measuring in bitcoin terms due to the superiority monetary
        properties rewards you tremendously over time!
      </Card>

      <div className="opportunityContainer">
        <div className="calculator">
          <Calculator />
        </div>

        <div className="historicalChart">
          <BtcHistoricalChart />
        </div>

        <div style={{ clear: "both" }}></div>
      </div>
    </div>
  );
};

export default OpportunityCost;
