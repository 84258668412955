import React from "react";
import Spinner from "react-bootstrap/Spinner";
import CoinGecko from "coingecko-api";
import firebase from "firebase";
import "../styles/Calculator.css";

const CoinGeckoClient = new CoinGecko();

export default class Calculator extends React.Component {
  state = {
    amount: 1,
    accumulate: 1,
    btcAccumulated: 0,
    usdInvested: 0,
    btcPrice: 0,
    data: [],
    spinner: false,
    loading: true,
  };

  async componentDidMount() {
    try {
      await this.getBtcCurrentPrice();
      await this.getHistoricalData(this.state.accumulate);

      this.setState({ loading: false });
    } catch (error) {
      console.error(error.message);
    }
  }

  // funciton gets historical btc price data
  getHistoricalData = (series) => {
    return new Promise(async (resolve, reject) => {
      try {
        // turn on spinner
        this.setState({ spinner: true });

        const { data } = await CoinGeckoClient.coins.fetchMarketChart(
          "bitcoin",
          {
            days: series * 365,
          }
        );

        // format the data, and calculate necessary totals
        let prices = [];
        let total = 0;
        data.prices.forEach((price) => {
          prices.push({ Price: price[1], Timestamp: new Date(price[0]) });
          total += this.state.amount / price[1];
        });

        // set states
        this.setState(
          {
            data: prices,
            btcAccumulated: total * this.state.btcPrice,
            usdInvested: this.state.amount * series * 365,
            spinner: false,
          },
          () => resolve()
        );
      } catch (error) {
        reject(error);
      }
    });
  };

  // function pulls BTC's current price from Nomics API
  getBtcCurrentPrice = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await CoinGeckoClient.coins.fetch("bitcoin");

        this.setState({ btcPrice: data.market_data.current_price.usd }, () =>
          resolve()
        );
      } catch (error) {
        reject(error);
      }
    });
  };

  render() {
    if (!this.state.loading) {
      return (
        <div className="Calculator">
          <h4 className="dcaHeader">DCA Calculator</h4>

          <form className="dcaContainer">
            <label className="label">Daily Purchase Amount</label>

            <div>
              <div className="dollarLabel">$</div>
              <input
                className="dcaInput"
                onChange={(e) =>
                  this.setState({
                    amount: parseInt(e.target.value.replace(/\$/g, "")),
                  })
                }
                value={this.state.amount}
                type="number"
                min="1"
              />
            </div>

            {this.state.accumulate > 1 ? (
              <label className="label">
                Accumulate for past {this.state.accumulate} years
              </label>
            ) : (
              <label className="label">
                Accumulate for past {this.state.accumulate} year
              </label>
            )}
            <input
              className="dcaInput"
              onChange={(e) => this.setState({ accumulate: e.target.value })}
              type="range"
              value={this.state.accumulate}
              min="1"
              max="10"
            />
          </form>

          <p className="portfolioHeader">
            <span className="label">Total Invested:</span> $
            {Math.round(this.state.usdInvested)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </p>

          <p className="portfolioHeader">
            <span className="label">Portfolio Value Today:</span> $
            {Math.round(this.state.btcAccumulated)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </p>

          <p className="portfolioHeader">
            <span className="label">Portfolio Return:</span>{" "}
            <span
              style={{
                color:
                  Math.round(
                    ((this.state.btcAccumulated * this.state.btcPrice -
                      this.state.usdInvested) /
                      this.state.usdInvested) *
                      100
                  ) >= 0
                    ? "green"
                    : "red",
              }}
            >
              {Math.round(
                ((this.state.btcAccumulated - this.state.usdInvested) /
                  this.state.usdInvested) *
                  100
              )
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              %
            </span>
          </p>

          <button
            className="calculateButton"
            onClick={() => {
              this.getHistoricalData(this.state.accumulate);
              firebase.analytics().logEvent("dca-button");
            }}
            disabled={this.state.spinner}
          >
            {!this.state.spinner ? (
              "Calculate"
            ) : (
              <Spinner animation="border" size="sm" variant="dark" />
            )}
          </button>
        </div>
      );
    } else {
      return (
        <div className="spinnerContainer">
          <Spinner className="spinner" animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      );
    }
  }
}
