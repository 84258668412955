import React, { useState } from "react";
import { makeStyles, Grid, Snackbar } from "@material-ui/core";
import { Form, Button } from "react-bootstrap";
import firebase from "firebase";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(10),
  },
  button: {
    backgroundColor: "#ff9900",
    color: "black",
    border: "none",
  },
}));

const AddArticle = () => {
  const classes = useStyles();

  const [auth, setAuth] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);

  // function tries password
  const tryPassword = (e) => {
    // prevent page from refreshing
    e.preventDefault();

    const password = e.target.elements.password.value.trim();

    if (password === process.env.REACT_APP_PASSWORD) {
      setAuth(true);
    } else {
      alert("Password incorrect");
    }

    e.target.reset();
  };

  // function adds url to database with metadata
  const addUrl = async (e) => {
    try {
      setLoading(true);

      // prevent page from refreshing
      e.preventDefault();

      const url = e.target.elements.url.value.trim();
      const urlRegEx = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

      if (urlRegEx.test(url)) {
        await firebase.functions().httpsCallable("addArticle")({ url });
        setConfirmation(true);
      } else {
        alert("Invalid URL");
      }
    } catch (alert) {
      alert("Error adding article");
    } finally {
      e.target.reset();
      setLoading(false);
    }
  };

  return (
    <Grid className={classes.container} justify="center" container>
      <Grid md={8} sm={8} xs={8} item>
        <Form onSubmit={auth ? addUrl : tryPassword}>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>{auth ? "URL" : "Password"}</Form.Label>

            <Form.Control
              className="input"
              placeholder={auth ? "Enter URL..." : "Enter password..."}
              name={auth ? "url" : "password"}
              type={!auth && "password"}
              required
            />
          </Form.Group>

          <Button
            className={classes.button}
            variant="primary"
            type="submit"
            disabled={loading}
            block
          >
            {auth ? "Add Article" : "Submit"}
          </Button>
        </Form>
      </Grid>

      <Snackbar
        open={confirmation}
        onClose={() => setConfirmation(false)}
        autoHideDuration={3000}
        message="Article added"
      />
    </Grid>
  );
};

export default AddArticle;
