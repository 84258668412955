import React from "react";
import Button from "react-bootstrap/Button";
import { GiBrain } from "react-icons/gi";
import { AiOutlineAreaChart } from "react-icons/ai";
import { IoBuild } from "react-icons/io5";
import firebase from "firebase";
import "../styles/Services.css";

const Services = () => {
  return (
    <div className="Services">
      <h1 className="headerCentered">Our Services</h1>

      <div className="serviceSummary">
        <h5>Our goal is simple.</h5>
        <h5>
          Work to develop the most effective ways for individuals and businesses
          to integrate with the Bitcoin Network, and utilize Bitcoin as a
          reserve asset, as it is the best monetary medium humanity has ever had
          at its disposal.
        </h5>
      </div>

      <div className="servicesContainer">
        <div className="service">
          <GiBrain size={100} color="#ff9900" />
          <h4>Understand</h4>
          <ul>
            <li>
              The Bitcoin Network; Understanding things at the protocol level.
            </li>
            <li>
              BTC/bitcoin the asset; Monetary policy, immutability, absolute
              scarcity.
            </li>
            <li>Bitcoin vs Altcoins; What makes Bitcoin truly different.</li>
            <li>
              Evolving global & domestic macroeconomic trends, fiscal & monetary
              policy.
            </li>
            <li>Monetary economics; The dynamics of sound money.</li>
            <li>
              Network effects & winner take all dynamics in the digital age.
            </li>
          </ul>
        </div>

        <div className="service">
          <AiOutlineAreaChart size={100} color="#ff9900" />
          <h4>Strategize</h4>
          <ul>
            <li>
              Analyze income & cash flow statements, as well as balance sheet
              structure.
            </li>
            <li>
              Establish initial target bitcoin allocation, tailored to the risk
              tolerance and time preference of the client.
            </li>
            <li>
              Develop recurring bitcoin accumulation strategies best aligned
              with long term goals and prospects of the client.
            </li>
            <li>Determine preferred exchange provider and custody solution.</li>
          </ul>
        </div>

        <div className="service">
          <IoBuild size={100} color="#ff9900" />
          <h4>Utilize</h4>
          <ul>
            <li>
              Help onboard clients with established and reputable Bitcoin
              onramp.
            </li>
            <li>
              If wanted, integrate point of sales merchant system to accept
              bitcoin payments.
            </li>
            <li>
              Utilize the Bitcoin Network to hold your money in a self
              sovereign, trustless way.
            </li>
            <li>
              Integrate bitcoin the asset into current accounting systems.
            </li>
          </ul>
        </div>
      </div>

      <div className="contactButtonContainer">
        <Button
          className="contactButton"
          onClick={() => firebase.analytics().logEvent("contact-us-button")}
          vairant="primary"
          href="/contact"
        >
          Contact Us
        </Button>
      </div>
    </div>
  );
};

export default Services;
